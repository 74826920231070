$INTELLIGENT_BLUE_0_5_OPACITY: hsla(from var(--color-intelligent-blue-600) h s l / 0.5)
$ALERT_ORANGE_600_0_5_OPACITY: hsla(from var(--color-alert-orange-600) h s l / 0.5)
$OPTIMISTIC_RED_700_0_5_OPACITY: hsla(from var(--color-optimistic-red-700) h s l / 0.5)

.Marker
    width: 20px
    height: 20px
    border-radius: 50%
    background: var(--color-intelligent-blue-700)
    display: flex
    justify-content: center
    align-items: center

    &[data-variant="primary"]
        background: var(--color-intelligent-blue-700)
        outline: 2px var(--color-materials-porcelain) solid

        &[data-highlight="normal"]
            background: var(--color-intelligent-blue-700)

        &[data-highlight="warning"]
            background: var(--color-alert-orange-700)

        &[data-highlight="danger"]
            background: var(--color-optimistic-red-700)

    &[data-variant="primary-outlined"]
        border: 1px solid var(--color-materials-porcelain)
        width: var(--spacing-16)
        height: var(--spacing-16)

        &[data-highlight="normal"]
            background: var(--color-intelligent-blue-700)

        &[data-highlight="warning"]
            background: var(--color-alert-orange-700)

        &[data-highlight="danger"]
            background: var(--color-optimistic-red-700)

    &[data-variant="outlined"]
        background: transparent
        border: 2px solid $INTELLIGENT_BLUE_0_5_OPACITY

        &[data-highlight="normal"]
            border-color: $INTELLIGENT_BLUE_0_5_OPACITY

        &[data-highlight="warning"]
            border-color: $ALERT_ORANGE_600_0_5_OPACITY

        &[data-highlight="danger"]
            border-color: $OPTIMISTIC_RED_700_0_5_OPACITY

        .inner
            display: none
    .inner
        width: var(--spacing-4)
        height: var(--spacing-4)
        background: var(--color-materials-porcelain)
        border-radius: 50%
