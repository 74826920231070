.spinner
  animation: rotate 2s linear infinite
  flex: 0 0 auto
  height: var(--spacing-16)
  width: var(--spacing-16)

.circle
  animation: dash 1.5s ease-in-out infinite
  stroke: var(--color-materials-rubber)
  stroke-linecap: round

@keyframes rotate
  100%
    transform: rotate(360deg)

@keyframes dash
  0%
    stroke-dasharray: 1, 200
    stroke-dashoffset: 0

  50%
    stroke-dasharray: 89, 200
    stroke-dashoffset: -35px

  100%
    stroke-dasharray: 89, 200
    stroke-dashoffset: -124px
