.content
    display: flex
    flex-direction: column
    gap: var(--spacing-16)

.header
    display: grid
    grid: auto-flow / auto 1fr auto
    gap: var(--spacing-12)
    place-items: center start

.squircle
    display: flex
    place-items: center
    place-content: center
    position: relative

    path
        fill: currentColor

    &[data-variant="subtle"]
        color: var(--color-materials-light-aluminium)
    &[data-variant="warning"]
        color: var(--color-optimistic-red-50)

.icon
    position: absolute
    &[data-variant="revoked"]
        color: var(--color-optimistic-red-400)
    &[data-variant="expired"]
        color: var(--color-optimistic-red-400)

.skeleton
    height: 14px
