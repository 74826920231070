.PaymentFormSkeleton
  display: grid
  gap: var(--spacing-16)

  .skeletonSection
    display: flex
    justify-content: space-between
    gap: var(--spacing-8)

.bottomSection
  display: flex
  justify-content: space-between
  gap: 8

.inputSkeleton
  padding-bottom: var(--spacing-12)
  display: flex
  flex-direction: column
  gap: var(--spacing-8)
  flex: 1
  border-bottom: 1px solid var(--color-intelligent-blue-600)
  .top
    height: var(--spacing-12)
    width: 40px
    border-radius: var(--spacing-16)
  .bottom
    border-radius: var(--spacing-16)
    height: 20px
    width: 80%
