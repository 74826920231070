.Expandable
  display: grid
  grid-template-rows: 0fr
  opacity: 0
  transition: grid-template-rows 0.3s ease-in, opacity 0.2s
  &[data-open=true]
    grid-template-rows: 1fr
    opacity: 1
    transition-delay: 0s, 0.3s

  .content
    overflow: hidden
