.container
    min-height: 100%
    display: flex
    place-content: center
    place-items: center
    background: linear-gradient(0deg, rgba(38, 44, 46, 0.04) 0%, rgba(38, 44, 46, 0.04) 100%), rgba(240, 240, 240, 0.80)
    backdrop-filter: blur(20px)

    @media (min-width: 440px)
        place-items: center

.content
    display: flex
    flex-direction: column
    gap: var(--spacing-24)
    width: 100%
    height: 100%
    max-width: 440px
    padding: var(--spacing-36) var(--spacing-24) var(--spacing-36) var(--spacing-24)

.imageContainer
    display: grid
    place-items: center

    & > *
        grid-area: 1/1

    .loader
        width: 100%
        aspect-ratio: 3 / 4
        height: auto
        border-radius: 15px
        overflow: hidden
        transition: opacity 0.5s ease
        opacity: 1

        &[data-loading='false']
            opacity: 0

    .image
        flex-shrink: 1
        width: 100%
        display: none

        &[data-loading='false']
            display: block

.buttons
    display: grid
    grid-template-columns: repeat(2, 1fr)
    flex-shrink: 0
    gap: var(--spacing-12)

.fullWidth
    width: 100%
