@use "render/styles/breakpoints"

$root-padding: var(--spacing-24)

.AppointmentInfoSection
  display: grid
  position: relative
  gap: var(--spacing-52)
  @media (max-width: breakpoints.$md)
    gap: var(--spacing-36)

  .head
    min-height: 250px
    max-height: 600px
    overflow: hidden
    background-color: var(--color-steel-grey-100)

  .hero
    width: 100%
    height: 100%
    aspect-ratio: 16/9
    @media (max-width: breakpoints.$md)
      aspect-ratio: 1 / 1

  .image
    transition: opacity 0.3s ease-out
    object-fit: cover
    width: 100%
    height: 100%

    &[data-loaded="false"]
      opacity: 0

  .badge
    display: flex
    gap: var(--spacing-8)
    align-items: center
    padding-bottom: var(--spacing-12)

  .content
    padding: 0 $root-padding

  .body
    box-sizing: border-box
    margin-left: auto
    margin-right: auto
    max-width: 920px
    column-gap: var(--spacing-72)
    row-gap: var(--spacing-52)
    display: grid
    grid-template-columns: 1fr 360px
    grid-template-rows: 1fr auto

    @media (max-width: breakpoints.$md)
      grid-template-columns: 1fr
      max-width: 440px

    @media (max-width: breakpoints.$sm)
      max-width: unset

  .top
    gap: var(--spacing-24)
    flex-direction: column
    display: flex

  .map
    padding: 0
    background-color: #f5f5f5 // map background color
    border-radius: 16px
    overflow: hidden
    border: 1px solid var(--color-steel-grey-300)
    position: relative
    aspect-ratio: 16/9

    @media (max-width: breakpoints.$md)
      display: none

    img
      position: absolute
      width: 920px
      height: 100%
      left: 50%
      translate: -50% 0
      object-position: center
      object-fit: cover

  .mapPin
    position: absolute
    top: 50%
    left: 50%
    // origin of the point of the pin
    transform: translate(-50%, -85%)

  .stepsSection
    grid-column: 2
    grid-row: span 5

    @media (max-width: breakpoints.$md)
      grid-row: initial
      grid-column: 1

  .stepsContainer
    background: var(--color-steel-grey-100)
    padding: var(--spacing-24)
    border-radius: var(--spacing-16)
    position: sticky
    top: 76px // sticky header
    overflow-y: auto
    overflow-x: hidden
    @media (min-width: breakpoints.$md)
      margin-bottom: var(--spacing-24)

  .sectionFAQ
    display: flex
    flex-direction: column
    gap: var(--spacing-24)

  .mobileProfileButton
    display: none
    position: sticky
    bottom: var(--spacing-16)
    left: $root-padding
    right: $root-padding

    @media (max-width: breakpoints.$md)
      display: initial

.timeLocation
  display: flex
  gap: var(--spacing-12)
  border-top: 1px solid var(--color-steel-grey-300)
  padding-top: var(--spacing-24)

  .icon
    padding: var(--spacing-4)

  .textOuter
    gap: var(--spacing-8)
    display: flex
    flex-direction: column

  .textInner
    gap: var(--spacing-4)
    display: flex
    flex-direction: column

  .link
    margin: calc(0px - var(--spacing-4)) calc(0px - var(--spacing-8))
