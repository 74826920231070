.text
  display: grid
  gap: var(--spacing-8)

.header
  display: flex
  flex-wrap: wrap
  gap: 1ch

.name
  color: #FBFBFA
