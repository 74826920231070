@use "render/styles/breakpoints"

.bottomSheet
  background-color: var(--color-materials-porcelain)
  border-radius: var(--spacing-24) var(--spacing-24) 0 0
  inset: 0
  padding: 24px
  position: absolute
  top: unset

  &[data-active="true"]
    opacity: 1
    transition: opacity 0.2s, translate 0.35s

  &[data-active="false"]
    opacity: 0
    translate: 0 100%
    transition: opacity 0.15s linear 0.2s, translate 0.35s

  @media (min-width: breakpoints.$md)
    border-radius: var(--spacing-24)
    inset: unset
    left: 50%
    top: 50%

    &[data-active="true"]
      translate: -50% -50%
      transition: unset

    &[data-active="false"]
      translate: -50% 100%

.focusItem
  clip: rect(0 0 0 0)
  clip-path: inset(50%)
  height: 1px
  overflow: hidden
  position: absolute
  white-space: nowrap
  width: 1px
