.body
  display: flex
  justify-content: center
  align-items: center
  overflow: auto

.content
  overflow: initial
  max-width: 540px
  width: 100%
  margin-top: 0
  margin-top: auto
  margin-bottom: auto
  display: grid
  gap: var(--spacing-12)
  @media (max-width: 600px)
    overflow: hidden
    margin: auto var(--spacing-16) var(--spacing-16) var(--spacing-16)

.actionButton
  width: 100%
  cursor: pointer
  border-radius: var(--spacing-16)
  padding: var(--spacing-24) var(--spacing-24) var(--spacing-24) var(--spacing-24)
  transition: all 0.6s
  background: var(--color-materials-porcelain)
  border: none
  display: flex
  flex-direction: row
  justify-content: space-between
  text-align: left
  color: var(--color-materials-rubber)

  svg
    transition: all 0.6s

  @media(hover: hover) and (pointer: fine)
    &:hover
      border-radius: 0
      & *
        color: var(--color-intelligent-blue-600) !important

      svg
        fill: var(--color-intelligent-blue-600)
