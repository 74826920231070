.BurgerLayout
  display: flex
  flex-flow: column
  height: 100%
  pointer-events: all

  & > header
    flex: 0 1

  & > main
    flex: auto
    height: 100%
    overflow: hidden

    & > *
      height: 100%

  & > footer
    flex: 0 1

