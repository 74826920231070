.title
  display: flex
  gap: var(--spacing-8)
  place-items: baseline

.row
  display: flex
  align-items: center
  justify-content: space-between

.code
  text-decoration: line-through
  color: var(--color-steel-grey-500)
