.DayScroll
  display: grid
  grid-auto-flow: column
  overflow: auto
  gap: var(--spacing-12)
  padding-right: calc(50% - 18px)
  padding-left: calc(50% - 18px)
  width: 100%
  white-space: nowrap
  -ms-overflow-style: "none"
  scrollbar-width: "none"
  scroll-snap-type: x mandatory
  &.dark
    --active-background-color: #019dc9
    --active-text-color: #fbfbfa
    --available-text-color: #fbfbfa
    --available-border-color: #9ebcc3
  &.light
    --active-background-color: #019dc9
    --active-text-color: #fbfbfa
    --available-text-color: #263032
    --available-border-color: #9ebcc3
  &::-webkit-scrollbar
    display: none

  button
    box-sizing: border-box
    width: var(--spacing-36)
    height: var(--spacing-36)

    cursor: pointer
    border-radius: 14px
    border: none
    justify-content: center
    align-items: center
    display: flex
    text-transform: capitalize
    font-weight: 500
    font-size: 12px
    letter-spacing: -0.8px
    line-height: 1.2

    &.selected
      background-color: var(--active-background-color)
      color: var(--active-text-color)
    &.available
      background-color: transparent
      color: var(--available-text-color)
      border: 1px solid var(--available-border-color)
    &:disabled

      cursor: initial
      border: none
