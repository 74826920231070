
.AssignmentProgress
  display: flex
  flex-direction: column
  font-size: 12px
  letter-spacing: 0.6px
  line-height: 1.2
  &.gap
    gap: var(--spacing-8)

  .header
    text-transform: uppercase
    font-weight: 500
    color: #4c6a70

  .progress
    color: #4c6a70
    font-size: 14px
    letter-spacing: 0em
    line-height: 1.2

  .bolded
    font-weight: 500
    color: #263032
