.consentContainer
    display: grid
    gap: var(--spacing-36)

.section
    display: grid
    gap: var(--spacing-24)

.list
    margin: 0
    padding: 0
    li
        border: 0 solid var(--color-steel-grey-300)
        border-bottom-width: 1px
        display: block
        &:first-of-type
            border-width: 1px 0

.Layout
    display: flex
    flex-direction: column
    justify-content: center
    align-items: flex-start
    align-self: stretch
    gap: var(--spacing-16)
    padding: var(--spacing-12) var(--spacing-16)
    border-radius: var(--spacing-8)
    background-color: var(--color-materials-aluminium)
