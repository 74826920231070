@use "render/styles/breakpoints"

@keyframes load
    from
        opacity: 0
        transform: translateX(20px)
    to
        opacity: 1
        transform: translateX(0)

@keyframes load-mobile
    from
        opacity: 0
        transform: translateY(20px)
    to
        opacity: 1
        transform: translateY(0)

.container
    min-height: 100%
    background: linear-gradient(180deg, #E2EFF3 0%, #FBFBFA 288px)
    padding: var(--spacing-24) 40px
    width: 50vw

    @media (max-width: breakpoints.$md)
        padding: var(--spacing-16) var(--spacing-24)
        width: 100vw

    &[data-variant="warning"]
        background: linear-gradient(180deg, #FFF5E5 0%, #FBFBFA 288px)

.header
    display: flex
    flex-direction: column
    gap: var(--spacing-24)

.nav
    display: grid
    grid-template-columns: 1fr auto 1fr

.close
    fill: var(--color-materials-rubber)

.date
    display: flex
    flex-direction: row
    justify-self: center
    align-items: center
    gap: var(--spacing-12)

    p
        color: var(--color-materials-rubber)
        text-transform: uppercase

.chevronLeft
    &[data-disabled=true]
        opacity: 0.2

.chevronRight
    &[data-disabled=true]
        opacity: 0.2

.content
    padding-block: var(--spacing-8)
    display: flex
    flex-direction: column
    gap: var(--spacing-8)

.labelAndToggle
    display: flex
    flex-direction: row
    justify-content: space-between
    align-items: center

.row
    display: flex
    flex-direction: row
    gap: var(--spacing-8)
    align-items: center

    &[data-secondary]
        color: var(--color-steel-grey-700)

.changeIcon
    border-radius: var(--spacing-4)
    background: var(--color-materials-aluminium)
    &[data-direction='down']
        transform: rotate(180deg)

    &[data-direction='right']
        transform: rotate(90deg)

.values
    display: flex
    align-items: baseline
    gap: var(--spacing-12)

.value
    font-family: "Neko Numeral Display"
    color: var(--color-materials-rubber)
    font-size: 88px
    letter-spacing: 0px
    line-height: 1.2
    padding: 0
    margin: 0

.divider
    width: 1px
    height: var(--spacing-12)
    background: var(--color-steel-grey-300)

.bottomHalf
    display: flex
    flex-direction: column
    gap: var(--spacing-52)

    @media (max-width: breakpoints.$md)
        gap: var(--spacing-36)

.noDataRow
    display: flex
    flex-direction: column
    gap: var(--spacing-16)

    p
        color: var(--color-steel-grey-700)

.studies
    margin-block: var(--spacing-24)

.backgroundImage
    background-image: url('/assets/images/pointcloud-no-data.svg')
    background-size: cover
    background-position: center
    background-repeat: no-repeat
    height: 170px
    width: 100%

.slideIn
    height: 100%
    animation-name: load-mobile
    animation-duration: 0.6s
    animation-timing-function: ease-in
    animation-delay: 0.1s

    @media (min-width: breakpoints.$md)
        animation-name: load
