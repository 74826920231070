@use "render/styles/breakpoints"

.EditPage
    width: 100%
    height: 100%
    overflow: hidden

    .closeIcon

        .desktop
            display: none
            @media (min-width: breakpoints.$md)
                display: block

        .mobile
            display: block
            @media (min-width: breakpoints.$md)
                display: none

    .content
        overflow: auto
        height: 100%
        background-color: #fbfbfa
        display: flex
        flex-direction: column

    .header
        top: 0
        z-index: 1
        position: sticky
        backdrop-filter: blur(20px)

    .titleContainer
        display: grid
        gap: var(--spacing-12)

    .title
        *
            color: #263032

    .container
        display: flex
        flex-direction: column
        flex: 1
        gap: var(--spacing-36)
        padding: 44px var(--spacing-16) var(--spacing-16)
        @media (min-width: breakpoints.$md)
            padding: 44px 44px 0 44px

    .cta
        padding: var(--spacing-24) var(--spacing-16) 0
        position: sticky
        bottom: var(--spacing-16)
        @media (min-width: breakpoints.$md)
            padding: 44px
            bottom: 0
