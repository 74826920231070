.BookableTimeSlot
    padding: 0 var(--spacing-24)
    border: none
    background: transparent
    cursor: pointer
    width: 100%
    transition: all 0.3s ease-in
    &:hover
        background: #E2EFF3
    &[data-selected=true]
        background: #E2EFF3
        .time, .name, .address
            *
                color: #005B84
        .checkMark
            border-color: #019DC9
            background: #019DC9
            > svg
                opacity: 1

    .time
        *
            transition: all 0.3s ease-in

    .location
        flex: 1
        text-align: left
        *
            transition: all 0.3s ease-in
    .name
        *
            transition: all 0.3s ease-in

    .address
        *
            transition: all 0.3s ease-in

    .checkMark
        color: var(--color-materials-porcelain)
        transition: all 0.3s ease-in
        width: 32px
        height: 32px
        border-radius: var(--spacing-8)
        box-sizing: border-box
        border: 1px solid #C5D9DD
        display: flex
        justify-content: center
        align-items: center
        > svg
            transition: all 0.3s ease-in
            opacity: 0
    .content
        padding: var(--spacing-12) 0
        border-bottom: 1px solid #C5D9DD
        display: flex
        gap: var(--spacing-24)
        align-items: center
