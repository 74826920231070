.container
  display: flex
  flex-direction: column
  gap: var(--spacing-36)
  padding-bottom: var(--spacing-24)

.header
  display: flex
  gap: var(--spacing-16)

.titles
  flex-basis: 100%
  display: flex
  flex-direction: column
  gap: var(--spacing-8)

.actions
  flex-shrink: 0
  display: flex
  flex-direction: column
  align-items: center

.updated
  display: flex
  flex-direction: row
