
.NoDoctorCredentials
  display: flex
  gap: var(--spacing-16)
  place-items: center

.image
  $_size: 36px
  background-color: var(--color-steel-grey-400)
  border-radius: 8px
  color: var(--color-materials-porcelain)
  display: flex
  height: $_size
  place-content: center
  place-items: center
  width: $_size
