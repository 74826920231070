@keyframes draw
  to
    stroke-dashoffset: 0

.Candle
  position: relative
  height: 100%
  width: 100%
  display: flex
  justify-content: center
  &.hidden
    display: none

  svg
    cursor: pointer
    display: block
    .line
      stroke-width: 1px
      animation: draw
      animation-timing-function: cubic-bezier(.17,.67,.83,.67)
      animation-fill-mode: forwards
