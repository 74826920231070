.PatientInfo
  list-style: none
  padding: 0
  margin: 0

  li
    padding: var(--spacing-24) 0
    border-bottom: 1px solid #c5d9dd

    &:last-child
      margin-bottom: 0
      border-bottom: none
