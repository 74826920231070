.days
  gap: var(--spacing-8)
  display: grid
  grid-area: 1/1/2/2
  grid-template-columns: repeat(7, 1fr)
  list-style: none
  margin: 0
  padding: 0

.header
  gap: var(--spacing-8)
  display: grid
  grid-template-columns: repeat(7, 1fr)
  list-style: none
  margin: 0
  padding: 0
  width: 100%
  height: 100%
  text-align: center
  font-weight: 500
  color: #567E87
  font-size: 10px
  letter-spacing: 0px
  text-transform: uppercase
  line-height: 1
  margin-bottom: var(--spacing-8)

.day
  user-select: none
  background: transparent
  border: none
  cursor: pointer
  border-radius: 50%
  padding: var(--spacing-8)
  height: 33.6px
  width: 35.2px
  margin: 0
  color: var(--color-steel-grey-700)
  font-size: 16px
  letter-spacing: -0.8px
  line-height: 1.2
  font-weight: 400
  &:disabled
    cursor: default
    pointer-events: none

.dayListItem
  display: flex
  justify-content: center

.open
  font-weight: 500
  color: var(--color-materials-rubber)
  background-color: var(--color-materials-porcelain)

.monthHead
  display: grid
.monthName
  grid-area: 1/1/2/2
  display: flex
  justify-content: flex-start
.months
  display: grid

.navHeader
  display: flex
  justify-content: space-between
