.CheckInStepExplanationLayout
  display: flex
  flex-direction: column
  min-height: 100%

  .header
    position: sticky
    top: 0
    backdrop-filter: blur(20px)
    min-height: 64px

  .subHeader
    display: flex
    flex-direction: column
    gap: 28px
    max-width: 520px
    margin: 0 auto
    width: 100%

  .actions
    max-width: 520px
    width: 100%
    margin: 0 auto
    display: grid
    &[data-sticky=true]
      position: sticky
      bottom: var(--spacing-16)

  .body
    padding: 0 var(--spacing-24) var(--spacing-24)
    flex-direction: column
    display: flex
    flex-grow: 1
    gap: 32px

  .content
    padding-top: 58px
    max-width: 520px
    margin: 0 auto
    display: flex
    flex-direction: column
    flex: 1
    @media (max-width: 480px)
      padding-top: 18px

    .main
      display: flex
      flex-direction: column
      flex: 1
