$focus-ring-padding: 8px

.StepButton
  align-items: center
  background: transparent
  border: none
  cursor: pointer
  display: flex
  gap: var(--spacing-24)
  justify-content: flex-start
  margin: 0 (-$focus-ring-padding)
  padding: var(--spacing-24) $focus-ring-padding
  border-radius: $focus-ring-padding
  flex: 1

  &:focus-visible
    outline: -webkit-focus-ring-color auto 1px

  &[data-can-click=false]
    cursor: initial

  &:disabled
    pointer-events: none
    cursor: inherit
    color: inherit
    .chevron
      display: none

  &[data-completed=true]
    pointer-events: none
    .chevron
      visibility: hidden

.todoText
  flex: 1
  text-align: left

.iconContainer
  display: flex
  justify-content: center
  align-items: center
  color: var(--color-steel-grey-700)

  path
    stroke: currentColor

.chevron
  stroke: var(--color-materials-rubber)
