@use "render/styles/breakpoints"

@keyframes backdropFilter
  0%
    background: var(--color-materials-light-aluminium)
    backdrop-filter: none
  99%
    background: var(--color-materials-light-aluminium)
    backdrop-filter: none
  100%
    background: initial
    backdrop-filter: blur(20px)

@keyframes backdropFilterOff
  0%
    background-color: var(--color-materials-light-aluminium)
    backdrop-filter: none
  100%
    background-color: var(--color-materials-light-aluminium)
    backdrop-filter: none

@keyframes fade-in
  0%
    transform: translateY(30px)
    opacity: 0
  100%
    transform: translateY(0px)
    opacity: 1

.tabContentContainer
  animation: fade-in 2.3s
  animation-fill-mode: both
  animation-iteration-count: 1
  z-index: 1
  display: grid
  backdrop-filter: initial
  background-color: transparent
  margin-top: 0
  filter: blur(4px)
  transition: filter 0.6s

  &[data-active=true]
    filter: blur(0px)

  @media (max-width: breakpoints.$md)
    backdrop-filter: blur(20px)
    background-color: hsla(from var(--color-materials-light-aluminium) h s l / 0.5)

.tabContent
  padding: 0 var(--spacing-24)
  grid-column: 1
  grid-row: 1
  transition: opacity 0.4s
  &[data-active=true]
    opacity: 1
  &[data-active=false]
    opacity: 0
    display: none
  &[data-transitioning=true]
    opacity: 0
    display: initial

  @media (max-width: breakpoints.$md)
    border-left: none
    padding: 0px var(--spacing-24) 0

.contentContainer
  flex-direction: column
  flex: 1
  display: flex

.flex
  padding: 0 40px
  @media (max-width: breakpoints.$md)
    padding: 0

.content
  position: initial
  display: grid
  grid-template-columns: 1fr 1fr
  max-width: 1440px
  width: 100%
  margin: 60px auto 0

  padding-bottom: 44px
  flex-direction: row
  padding: 0
  @media (max-width: breakpoints.$md)
    position: relative
    grid-template-columns: 12fr
    margin: 0 auto
  @media (max-width: 640px)
    gap: 0px

.headerContainer
  padding-top: 32px
  padding-bottom: 20px
  margin-bottom: 44px
  background-color: var(--color-materials-light-aluminium)

  @media (max-width: 640px)
    padding-left: var(--spacing-16)
    padding-right: var(--spacing-16)
    padding-top: 0
    margin-bottom: 0
    display: flex
    flex-direction: column-reverse

.headerSticky
  position: sticky
  top: 0
  z-index: 2
  backdrop-filter: blur(20px)
  border-bottom: 1px solid var(--color-steel-grey-300)
  transition: transform 0.3s cubic-bezier(0.45, 0.00, 0.25, 1.00)
  transition-delay: 0.2s
  @supports (-moz-appearance:none)
    backdrop-filter: initial
    &[data-blured=false]
      animation: backdropFilter 0.5s forwards
    &[data-blured=true]
      animation: backdropFilterOff 0.5s forwards
  &[data-active=false]
    transform: translateY(-100%)
    transition-delay: 0s

.header
  max-width: 1200px
  width: 100%
  margin: 0 auto

.avatarSection
  position: initial
  height: initial
  width: initial

  @media (max-width: breakpoints.$md)
    position: absolute
    height: 100%
    width: 100%

.navTabsContainer
  display: flex
  justify-content: center
  .navTabs
    max-width: 1440px
    width: 100%
