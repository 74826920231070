.body
  display: grid
  gap: var(--spacing-36)
  margin: 0 auto
  max-width: 540px
  padding: var(--spacing-24)

.text
  display: grid
  gap: var(--spacing-12)

.header
  display: flex
  gap: var(--spacing-16)
  place-content: space-between
  place-items: flex-start

.content
  display: grid
  gap: var(--spacing-8)

.cardSkeleton
  height: 320px

.fileSizeSkeleton
  height: 18px
  width: 56px

.downloadButtonSkeleton
  height: 83px

.divider
  all: unset
  background-color: var(--color-materials-aluminium)
  display: block
  height: 1px

.row
  display: flex
  flex-wrap: wrap
  gap: var(--spacing-12)
  place-items: center

  & > *
    flex: 1
