@use "render/styles/breakpoints"

.body
  display: flex
  justify-content: center
  align-items: center
  overflow: auto
  position: relative

.content
  display: flex
  flex-direction: column
  justify-content: center
  max-width: 540px
  width: 100%
  margin: auto
  height: initial
  padding: var(--spacing-52) var(--spacing-16) var(--spacing-16) var(--spacing-16)
  height: inherit
  position: absolute
  gap: var(--spacing-36)

  @media (max-width: breakpoints.$sm)
    justify-content: space-between

.text
  display: flex
  flex-direction: column
  gap: var(--spacing-12)

.backgroundContainer
  position: fixed
  bottom: 0px
  right: 0px
  height: 50%
  aspect-ratio: 16 / 9

.backgroundImage
  background-image: url('/assets/images/pointcloud-no-data.svg')
  background-size: cover
  background-position: botton
  background-repeat: no-repeat
  height: 100%

.orderNumber
  display: flex
  flex-direction: row
  align-items: center
  gap: var(--spacing-8)
