.text
  display: grid
  gap: var(--spacing-8)

.header
  display: flex
  flex-direction: row

.whiteText
  color: #FBFBFA
