@use "render/styles/breakpoints"
$focus-ring-padding: 8px

.AddToCalendar
  display: flex
  flex-direction: column
  align-items: center
  gap: var(--spacing-24)
  margin: -$focus-ring-padding
  padding: $focus-ring-padding
  overflow: auto
  max-height: 80vh

.hideMobile
  color: var(--color-materials-rubber)
  @media (max-width: breakpoints.$md)
    display: none

.hideDesktop
  margin-bottom: -4px
  @media (min-width: breakpoints.$md)
    display: none

.buttons
  display: flex
  flex-direction: column
  gap: var(--spacing-12)
  align-self: stretch

  @media (min-width: breakpoints.$md)
    width: 310px

.header
  display: flex
  align-self: stretch
  justify-content: space-between
  @media (max-width: breakpoints.$md)
    justify-content: center

.outlook
  align-items: center
  display: flex
  flex-direction: column
