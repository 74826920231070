@use "render/styles/breakpoints"

.NoProgressTable
  display: flex
  justify-content: center
  svg
    width: 100%
    height: 350px
    margin-top: var(--spacing-24)
    @media (max-width: breakpoints.$md)
      height: auto
