@mixin scrollbars($color)
  scrollbar-color: $color transparent
  scrollbar-width: thin,

  &::-webkit-scrollbar
    height: var(--spacing-4)
    width: var(--spacing-4)

  &::-webkit-scrollbar-track
    background: transparent

  &::-webkit-scrollbar-thumb
    background: $color
    border-radius: 2px
