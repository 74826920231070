.text
  padding-top: var(--spacing-16)
  display: grid
  gap: var(--spacing-4)
  text-align: left

.cta
  padding-top: 22px
  display: flex
  flex-wrap: nowrap
  flex-direction: row
  gap: var(--spacing-8)
  > *
    flex: 1

.button
  height: 100%
  padding: var(--spacing-24)
  width: 100%
  box-sizing: border-box
  display: flex
  flex-direction: column
  justify-content: center
  align-items: flex-start
  background-color: var(--color-materials-porcelain)
  position: relative
  border-radius: var(--spacing-16)
  border: none
  transition: all 0.6s
  cursor: pointer
  justify-content: space-between
  color: var(--color-materials-rubber)

  @media(hover: hover) and (pointer: fine)
    &:hover
      border-radius: 0px
      color: var(--color-intelligent-blue-600) !important

      & svg
        fill: var(--color-intelligent-blue-600) !important

      & *
        color: var(--color-intelligent-blue-600) !important
