.button
  width: 100%
  display: flex
  border-radius: var(--spacing-16)
  border: none
  cursor: pointer
  padding: var(--spacing-24)
  transition: border-radius 0.6s, color 0.6s
  justify-content: space-between
  align-items: center
  font-family: "IBM Plex Sans Medium"
  font-size: 16px
  font-style: normal
  font-weight: 500
  line-height: 24px
  letter-spacing: -0.1px
  &[data-variant="primary"]
    background: var(--color-materials-porcelain)
    color: var(--color-steel-grey-900)
    @media (hover: hover)
      &:hover
        color: var(--color-intelligent-blue-600)

  &[data-variant="danger"]
    background: var(--color-optimistic-red-400)
    color: var(--color-materials-porcelain)

  &[data-variant="secondary"]
    background: var(--color-materials-light-aluminium)
    color: var(--color-steel-grey-700)
    border: 1px solid var(--color-steel-grey-400)

  &[data-variant="suggestion"]
    background: var(--color-intelligent-blue-600)
    color: var(--color-materials-porcelain)

  &[data-variant="wallet"]
    background: var(--color-materials-rubber)
    color: var(--color-materials-porcelain)

  &:focus-visible
    outline: -webkit-focus-ring-color solid 1px
    outline-offset: var(--spacing-4)

  &:disabled
    opacity: 0.5
    cursor: not-allowed

  @media (hover: hover)
    .arrowIcon
      transform: translateX(10px)
      padding-right: 10px

    &:not(:disabled)
      &:hover
        border-radius: 0px
        .arrowIconRight
          transform: translateX(0px)

        .arrowIconLeft
          transform: translateX(-40px)

.arrowIcon
  overflow: hidden
  width: 40px
  display: grid
  justify-content: flex-end
  > *
    grid-area: 1/1/2/2

.arrowIconRight
  transform: translateX(40px)

.arrowIconRight, .arrowIconLeft
  transition: transform 0.8s cubic-bezier(.15,.57,.48,1.55)
