@mixin _size
  $_size: 32px
  height: $_size
  width: $_size

@mixin  _hideWhenUnchecked
  transition: opacity 0.2s ease
  .input:not(:checked) ~ &
    opacity: 0
    transition: opacity 0.2s ease, visibility 0s 0.2s
    visibility: hidden

.label
  cursor: pointer
  display: flex
  gap: var(--spacing-16)
  place-items: center
  transition: 0.2s
  transition-property: border-color, color

  &[data-bordered="true"]
    border: 1px solid var(--color-steel-grey-300)
    border-radius: var(--spacing-16)
    padding: var(--spacing-24)
    place-content: space-between
    &:has(:checked)
      border-color: var(--color-intelligent-blue-600)
      color: var(--color-intelligent-blue-900)
    @media (hover: hover)
      &:hover
        border-color: var(--color-intelligent-blue-600)
        color: var(--color-intelligent-blue-900)

  &[data-inverted="true"]
    .inputContainer
      order: 2

.inputContainer

  display: flex
  justify-content: center
  align-items: center
  position: relative

.input
  all: unset
  @include _size

.squircleOutline
  @include _size
  position: absolute

.squircle
  @include _size
  @include _hideWhenUnchecked
  position: absolute

.check
  @include _hideWhenUnchecked
  position: absolute
  color: var(--color-materials-porcelain)
