.card
  width: 100%
  height: auto
  aspect-ratio: 3 / 4
  display: flex
  flex-direction: column
  overflow: hidden
  border-radius: 15px

  .content
    display: grid
    overflow: hidden
    height: 100%

    > *
      grid-area: 1/1

    .bg
      object-fit: cover
      object-position: center
      width: 100%
      height: 100%
      overflow: hidden

    .body
      padding: 28px 28px var(--spacing-16) 28px
      background-size: cover
      background-position: center
      display: flex
      flex-direction: column
      justify-content: space-between

      .logo
        display: flex
        place-content: center

        svg
          width: 76px

  &Footer
    background: #ffff78
    display: grid
    grid-template-columns: repeat(2, 1fr)
    padding: 20px 28px
    gap: 2px

    span
      font-family: "IBM Plex Mono"
      font-size: 12px
      font-style: normal
      font-weight: 400
      line-height: 14px
      letter-spacing: -0.12px
      text-transform: uppercase
      font-variant-numeric: slashed-zero

      &:nth-child(even)
        text-align: right
