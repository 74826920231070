@keyframes fade
    from
        opacity: 0
        transform: scale(0)
    50%
        opacity: 1
    100%
        transform: none

@keyframes slide-in-out
    from
        opacity: 0
        transform: translateY(50%) translateX(-50%)
    50%
        opacity: 1
        transform: translateY(0) translateX(-50%)
    100%
        opacity: 0

@keyframes slide-in
    from
        opacity: 0
        transform: translateY(50%) translateX(-50%)
    80%
        opacity: 1
        transform: translateY(0) translateX(-50%)
    100%
        opacity: 1
        transform: translateY(0) translateX(-50%)

$first-marker-delay: 0.5s
$second-marker-delay: 2.2s

.RangeChart
    display: grid
    gap: var(--spacing-4)
    margin: 30px 0 0
    max-width: 500px

    .Container
        display: grid
        gap: var(--spacing-12)

    .Range
        position: relative
        background-color: black
        height: var(--spacing-8)
        background: var(--color-materials-aluminium)
        min-width: 0
        transition: top 0.2s, left 0.2s, width 0.2s, height 0.2s
        border-radius: 2px

        &[data-variant="normal"]
            background: var(--color-intelligent-blue-200)

        &[data-variant="warning"]
            background: var(--color-alert-orange-200)

        &[data-variant="danger"]
            background: var(--color-optimistic-red-200)

    .Marker
        position: absolute
        top: -6px
        z-index: 1
        animation: fade 0.5s backwards ease
        animation-delay: $first-marker-delay
        left: var(--left)

        &[data-clamp-right=true]
            right: 0
            left: unset

        &[data-variant="primary-outlined"]
            top: -4px
            animation-delay: $second-marker-delay
            left: calc(var(--left) + 2px)
            &[data-clamp-right=true]
                left: unset
                right: 2px

        &[data-variant="primary"]
            animation-delay: $second-marker-delay

        &[data-single]
            animation-delay: $first-marker-delay

        .value
            position: absolute
            top: -20px
            left: 50%
            transform: translateX(-50%)
            text-transform: uppercase
            min-width: max-content
            font-weight: 500
            opacity: 0
            animation: slide-in-out
            animation-duration: 2s
            animation-timing-function: ease
            animation-delay: 1s

            &[data-marker-variant='primary'], &[data-marker-variant='primary-outlined']
                animation-name: slide-in
                animation-delay: $second-marker-delay
                opacity: 1

            &[data-marker-single]
                animation-delay: $first-marker-delay

            &[data-range-variant='warning']
                color: var(--color-alert-orange-900)
                &[data-marker-variant='outlined']
                    color: var(--color-alert-orange-600)

            &[data-range-variant='danger']
                color: var(--color-optimistic-red-900)
                &[data-marker-variant='outlined']
                    color: var(--color-optimistic-red-600)

            &[data-range-variant='normal']
                color: var(--color-intelligent-blue-900)
                &[data-marker-variant='outlined']
                    color: var(--color-intelligent-blue-600)

    .RangeLabels
        display: flex
        justify-content: center
        color: var(--color-steel-grey-700)
        align-items: flex-start
        text-transform: uppercase
        text-align: center
        padding-inline: var(--spacing-4)

        &[data-variant="warning"]
            color: var(--color-alert-orange-900)

        &[data-variant="danger"]
            color: var(--color-optimistic-red-900)

        &[data-variant="normal"], &[data-variant='optimal']
            color: var(--color-intelligent-blue-900)
