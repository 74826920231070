.BookingUpcomingStage
  display: grid
  gap: 22px

.button
  text-align: left
  width: 100%

.text
  display: grid
  gap: var(--spacing-4)
