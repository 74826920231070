.SummariesTab
  animation-fill-mode: both
  animation-iteration-count: 1
  backgorund-color: var(--color-materials-porcelain)
  display: grid
  gap: var(--spacing-52)
  padding-bottom: var(--spacing-24)

.header
  display: grid
  gap: var(--spacing-16)
  grid: auto-flow / 1fr auto

.messages
  display: grid
  gap: var(--spacing-52)
  > :not(:first-of-type)
    border-top: 1px solid var(--color-steel-grey-300)
    padding-top: var(--spacing-52)

.buttons
  display: grid
  gap: var(--spacing-24)

  &[data-large="true"]
    display: flex
    gap: var(--spacing-36)
    place-content: center

  > [data-self="center"]
    place-self: center
