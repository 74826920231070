.container
  padding: var(--spacing-36)
  display: flex
  place-content: center
  align-content: center
  height: 100%
  width: 100%

.introText
  margin: auto
  text-align: center
  max-width: 554px
  width: 100%
  display: flex
  flex-direction: column
  gap: var(--spacing-24)
