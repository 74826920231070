.tabControlsContainer
    display: grid
    grid-template-columns: 1fr 1fr
    gap: var(--spacing-4)
    border-radius: var(--spacing-4)
    border: 1px solid rgba(38, 44, 46, 0.06)
    background: rgba(38, 44, 46, 0.06)

.tab
    all: unset
    padding: var(--spacing-8) var(--spacing-12)
    text-transform: uppercase
    border-radius: var(--spacing-4)
    text-align: center
    cursor: pointer
    transition: background 0.3s ease-in

    &[aria-selected=true]
        background: var(--color-materials-porcelain)

        p
            color: var(--color-materials-rubber)
