.InfoField
  all: unset
  width: 100%
  display: flex
  flex-direction: column
  gap: var(--spacing-4)
  cursor: pointer
  user-select: none
  .value
    color: #019dc9

  &:focus-visible
    outline: -webkit-focus-ring-color solid 1px

  &[data-canedit=false]
    cursor: initial
    .value
      color: #263032

  .value
    font-weight: 300
    font-style: normal

    font-size: 24px
    letter-spacing: -0.6px
    line-height: 1.3

  .row
    display: flex
    justify-content: space-between
    align-items: center
