.FreeText
  color: var(--color-materials-rubber)
  display: grid
  font-family: var(--typography-bodyM-fontFamily)
  font-size: var(--typography-bodyM-fontSize)
  font-style: var(--typography-bodyM-fontStyle)
  font-weight: var(--typography-bodyM-fontWeight)
  gap: var(--spacing-12)
  letter-spacing: var(--typography-bodyM-letterSpacing)
  line-height: var(--typography-bodyM-lineHeight)
  overflow: hidden
  white-space: pre-wrap

  *
    margin: 0

  ul
    display: grid
    gap: var(--spacing-4)
    padding: 0

    li
      $_markerSize: 6px
      position: relative
      padding-left: calc(var(--spacing-16) + $_markerSize)

      &::before
        background-color: var(--color-intelligent-blue-600)
        border-radius: 2px
        color: transparent
        content: '-'
        display: block
        height: $_markerSize
        left: 0
        position: absolute
        top: 9px
        width: $_markerSize

  h4:not(:first-of-type)
    margin-top: var(--spacing-12)
