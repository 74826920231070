.actions
    padding-bottom: var(--spacing-24)
    display: grid
    gap: var(--spacing-8)

.body
    flex-direction: column
    flex: 1
    display: flex
    @media (max-width: 600px)
        justify-content: space-between

.content
    display: flex
    flex-direction: column
    gap: 60px
    max-width: 540px
    margin: auto
    @media (max-width: 600px)
        flex: 1
        margin: 32px var(--spacing-24) 0 var(--spacing-24)

.text
    display: flex
    flex-direction: column
    gap: var(--spacing-12)
    flex: 1

.subText
    display: grid
    gap: var(--spacing-24)

.textWithIcon
    display: flex
    flex-direction: row
    align-items: center
    gap: var(--spacing-8)

.sticky
    position: sticky
    top: 0
    backdrop-filter: blur(20px)

.OnboardingIdvResultView
    min-height: 100%
    display: flex
    flex-direction: column

.skeletonBig
    height: 80px

.skeletonSmall
    height: 100px

.skeletonCTA
    height: 80px
