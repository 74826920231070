button.card
    cursor: pointer

.card
    all: unset
    background: var(--color-materials-porcelain)
    border-radius: var(--spacing-16)
    display: flex
    flex-direction: column
    gap: var(--spacing-24)
    padding: var(--spacing-24)

    &:focus-visible
        outline: -webkit-focus-ring-color solid 1px

    &[data-elevation="1"]
        border-radius: var(--spacing-16)
