.benchmark
    display: grid
    gap: var(--spacing-16)
    margin-bottom: var(--spacing-16)
    max-width: 500px

.keyline
    all: unset
    background-color: var(--color-steel-grey-300)
    height: 1px
    margin: var(--spacing-16) 0

.text
    display: grid
    gap: var(--spacing-8)

.measurement
    display: grid
    gap: var(--spacing-8)

.value
    display: flex
    gap: var(--spacing-8)
    place-items: baseline

.bar
    background-color: var(--color-materials-aluminium)
    border-radius: 12px
    color: var(--color-steel-grey-700)
    min-width: 20ch
    padding: var(--spacing-16) var(--spacing-12)
    transition: width 0.3s

    &[data-kind="positive"]
        background-color: var(--color-intelligent-blue-600)
        color: var(--color-materials-porcelain)
    &[data-kind="negative"]
        background-color: var(--color-alert-orange-100)
        color: var(--color-alert-orange-900)
