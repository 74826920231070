.choice
  all: unset
  justify-content: space-between
  display: flex
  align-items: center
  cursor: pointer
  padding: var(--spacing-24)
  transition: all 0.5s
  border-radius: var(--spacing-16)
  border: 1px solid var(--color-steel-grey-300)

  input
    border: 0
    padding: 0
    margin: 0
    position: absolute !important
    height: 1px
    width: 1px
    overflow: hidden
    /* IE6, IE7 - a 0 height clip, off to the bottom right of the visible 1px box */
    clip: rect(1px 1px 1px 1px)
    /*maybe deprecated but we need to support legacy browsers */
    clip: rect(1px, 1px, 1px, 1px)
    /*modern browsers, clip-path works inwards from each corner*/
    clip-path: inset(50%)
    /* added line to stop words getting smushed together (as they go onto seperate lines and some screen readers do not understand line feeds as a space */
    white-space: nowrap

  &[data-picked=true]
    color: var(--color-intelligent-blue-900)
    border-color: var(--color-intelligent-blue-600)
    svg
      *
        stroke: var(--color-intelligent-blue-600)

  svg
    transition: all 0.5s

  @media (hover: hover)
    &:hover
      color: var(--color-intelligent-blue-900)
      border-color: var(--color-intelligent-blue-600)
      svg
        *
          stroke: var(--color-intelligent-blue-600)

.icon
  width: var(--spacing-24)
  height: var(--spacing-24)

.content
  display: flex
  flex-direction: row
  gap: var(--spacing-16)
  justify-content: space-between
  width: 100%
  align-items: center
