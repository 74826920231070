@use "render/styles/breakpoints"

.ReportHistory
  display: grid
  gap: var(--spacing-24)
  padding: var(--spacing-36) var(--spacing-24)
  @media (min-width: breakpoints.$md)
    padding: var(--spacing-24) 40px

.list
  margin: 0
  padding: 0

.item
  border: 0 solid var(--color-steel-grey-300)
  border-width: 1px 0 0 0
  list-style: none

  &:last-of-type
    border-bottom-width: 1px

  > a
    display: flex
    gap: var(--spacing-36)
    padding: var(--spacing-24) 0
    place-content: space-between
    place-items: center

.itemText
  display: grid
  gap: var(--spacing-8)
