.info
  display: flex
  flex-direction: column
  gap: var(--spacing-16)

  a
    text-decoration: underline
    color: var(--color-steel-grey-700)

  .list
    margin: 0

.list
  padding: 0
  list-style-type: none
  display: flex
  flex-direction: column
  gap: var(--spacing-4)

  li
    gap: var(--spacing-16)
    display: flex

.bullet
  flex-shrink: 0
  height: var(--typography-bodyM-lineHeight)
  width: 6px
