.body
  display: flex
  justify-content: center
  align-items: center
  overflow: auto

.content
  max-width: 540px
  width: 100%
  margin-top: 0
  @media (max-width: 600px)
    margin: auto var(--spacing-16) var(--spacing-24) var(--spacing-16)

.text
  display: grid
  gap: var(--spacing-12)
  margin-bottom: var(--spacing-24)
  margin-top: 0
  @media (max-width: 600px)
    margin-top: 20px

.cta
  margin-top: var(--spacing-24)

.header
  padding: 34px 0 20px 0
  display: flex
  justify-content: center
