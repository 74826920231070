.text
  display: grid
  gap: var(--spacing-12)

.time
  display: flex
  flex-direction: row
  align-items: center
  gap: var(--spacing-8)
  padding-block-start: var(--spacing-8)
