.body
  display: flex
  justify-content: center
  align-items: center
  overflow: auto

.content
  display: flex
  flex-direction: column
  max-width: 540px
  width: 100%
  margin: auto
  height: initial
  @media (max-width: 600px)
    margin: auto var(--spacing-16) var(--spacing-16) var(--spacing-16)

.actions
  padding-top: 32px
