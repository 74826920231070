.TextInput
  border-bottom: 1px solid var(--color-steel-grey-300)
  display: grid

  & textarea
    outline: none
    overflow: hidden
    background: transparent
    font-family: "IBM Plex Sans Regular", sans-serif
    color: var(--color-intelligent-blue-900)
    caret-color: var(--color-intelligent-blue-900)
    border: none
    background: none
    padding: var(--spacing-24) var(--spacing-16) 26px var(--spacing-16)
    font-weight: 400
    font-style: normal
    font-size: 20px
    letter-spacing: -0.4px
    line-height: 24px
    resize: none
    width: 100%
    &::placeholder
      color: var(--color-intelligent-blue-900)
