.header
  position: sticky
  top: 0
  backdrop-filter: blur(20px)
  z-index: 1

.container
  background-color: var(--color-materials-light-aluminium)
  color: var(--color-steel-grey-900)
  height: 100%
  display: flex
  flex-direction: column
  overflow: auto

.tabs
  max-width: 540px
  margin: 0 auto

.contentContainer
  overflow-y: auto
  flex: 1

.content
  display: grid

.tab
  display: grid
  grid-column: 1
  grid-row: 1
  grid-template-rows: 0fr
  overflow: hidden
  visibility: hidden
  transition: opacity 0.25s ease-in, grid-template-rows 0.01s ease-in 0.25s, visibility 0s ease-in 0.3s
  opacity: 0
  pointer-events: none

  &[data-active=true]
    visibility: visible
    pointer-events: initial
    opacity: 1
    transition-delay: 0.3s, 0.3s, 0.3s
    grid-template-rows: 1fr

.tabContent
  min-height: 0

.frame
  width: 100%
  border-bottom: 1px solid #c5d9dd
