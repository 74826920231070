
.content
  margin: auto
  max-width: 540px
  width: 100%
  display: flex
  margin: auto
  text-align: center
  @media (max-width: 600px)
    text-align: left
    margin: auto var(--spacing-16) auto var(--spacing-16)

.body
  display: flex
  height: 100%
