@use '_easing' as *

.Accordion
  border-block-start: 1px solid var(--color-steel-grey-300)

.item
  border-block-end: 1px solid var(--color-steel-grey-300)
  text-align: start
  width: 100%
  display: flex
  flex-direction: column
  overflow: hidden
  position: relative

.header
  cursor: pointer
  display: flex
  align-items: center
  justify-content: space-between
  padding-block: var(--spacing-24)

  p
    color: var(--color-materials-rubber)

.button
  all: unset

  &:focus-visible
    outline: -webkit-focus-ring-color auto 1px

.expandable
  display: grid
  grid-template-rows: 0fr
  opacity: 0
  transition: grid-template-rows 0.3s $neko-75-55, opacity 0.2s $neko-75-55
  &[data-open=true]
    grid-template-rows: 1fr
    opacity: 1
    transition-delay: 0s, 0.25s

.content
  overflow: hidden
  &
    max-width: 500px
  .contentSpacing
    margin-bottom: var(--spacing-24)

.icon
  transform: rotate(0deg)
  transition: transform 0.5s

  &[data-open=true]
    transform: rotate(45deg)
