.OverviewStep
  align-items: center
  display: flex
  flex-direction: column
  justify-content: center
  gap: 44px

  .day
    text-transform: capitalize
    font-weight: 300
    font-style: normal
    color: #263032
    font-size: 36px
    letter-spacing: -1px
    line-height: 1.2
    text-align: center

  .item
    display: grid
    gap: var(--spacing-12)
    width: 100%

    .time
      color: #263032
      font-size: 14px
      letter-spacing: 0em
      line-height: 18px
      text-align: center
      display: flex
      align-items: center
      justify-content: center
      gap: var(--spacing-8)

    .data
      display: grid
      gap: var(--spacing-12)
      grid-template-columns: 1fr auto 1fr
      align-items: center
      width: 100%
      font-family: "Neko Numeral Text", sans-serif

      .sepparator
        font-size: 38px
        letter-spacing: 0em
        line-height: 1.2
        color: #9ebcc3
      button
        cursor: pointer
        border: none
        margin: 0
        padding: 0
        background: transparent
        color: #263032
        font-size: 64px
        letter-spacing: 0em
        line-height: 1.2
        &:disabled
          cursor: initial
        &:last-of-type
          text-align: left
        &:first-of-type
          text-align: right
