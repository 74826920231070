.videoBackground
  position: fixed
  width: 100vw
  top: 0
  left: 0
  height: 100vh
  z-index: -1
  object-fit: cover
  transition: all 0.1s
  // https://stackoverflow.com/a/53779216
  -webkit-mask-image: -webkit-radial-gradient(white, black)
  -webkit-backface-visibility: hidden
  -moz-backface-visibility: hidden

.videoBackgroundImage
  position: fixed
  width: 100vw
  top: 0
  left: 0
  height: 100vh
  z-index: -1
  object-fit: cover
  max-width: 100%
  max-height: 100vh
