.title
  display: flex
  gap: var(--spacing-8)
  place-items: baseline

.row
  display: flex
  align-items: center
  justify-content: space-between

.copyButton
  padding: var(--spacing-8) var(--spacing-12) var(--spacing-8) var(--spacing-12)
  border-radius: var(--spacing-12)
  cursor: pointer
  border: 1px solid var(--color-steel-grey-400)
  background-color: var(--color-materials-porcelain)
  color: var(--color-materials-rubber)

  &:focus-visible
    outline: -webkit-focus-ring-color solid 1px
