.header
  padding: 20px 20px var(--spacing-16) 20px
  display: flex
  align-items: center
  gap: var(--spacing-12)
  justify-content: space-between
  text-transform: capitalize
  transition: color 0.3s
  svg
    transition: color 0.3s

  &.light
    color: #263032
  &.dark
    color: #fbfbfa
    svg
      fill: rgb(1, 157, 201)

  @media (max-width: 500px)
    padding: var(--spacing-16) var(--spacing-16) var(--spacing-16) var(--spacing-16)

  .left, .right
    min-width: 0
    display: flex

  .right
    justify-content: flex-end

  .left
    justify-content: flex-start

  .date
    font-weight: 500
    font-style: normal
    font-size: 16px
    letter-spacing: -0.3px
    line-height: 18px
