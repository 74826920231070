.CheckInBadge
  display: flex
  gap: var(--spacing-8)
  align-items: center

  .text
    color: #263032
    font-family: "IBM Plex Sans Medium"
    font-size: 12px
    font-style: normal
    font-weight: 500
    line-height: normal
    letter-spacing: 0.24px
    text-transform: uppercase
