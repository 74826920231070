.CheckInFlowView
  width: 100%
  height: 100%
  display: flex
  flex-direction: column-reverse

.header
  position: absolute
  top: 0
  width: 100%
  backdrop-filter: blur(20px)
  height: 64px
  display: flex
  align-items: center
  justify-content: center
