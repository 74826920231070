@use "easing" as easing

.ProductPrice
  row-gap: var(--spacing-4)
  display: grid
  justify-content: space-between
  grid-template-columns: 1fr auto

.price
  display: grid
  grid-template-columns: 1fr auto
  gap: var(--spacing-8)

.addCode
  display: flex
  justify-content: space-between

.pricePlaceholder
  height: 100%
  width: 30px

.lineThrough
  text-decoration: line-through
  text-align: right
