.body
  display: grid
  gap: var(--spacing-12)
  margin: auto
  max-width: 540px
  overflow: hidden auto
  padding: var(--spacing-36) var(--spacing-24) var(--spacing-24)
  place-content: center

.sticky
  backdrop-filter: blur(20px)
  position: sticky
  top: 0
  z-index: 1

.footer
  padding-top: var(--spacing-12)

.studies
  list-style: none
  padding: var(--spacing-16) 0
  margin: 0

.study
  border: 0 solid var(--color-steel-grey-300)
  border-bottom-width: 1px
  display: grid
  gap: var(--spacing-24)
  padding: var(--spacing-36) 0
  place-items: center start
  &:first-of-type
    border-width: 1px 0

.card
  display: grid
  gap: var(--spacing-12)

.cardTitle
  display: flex
  gap: var(--spacing-16)
  place-content: space-between
  place-items: center

.cardLink
  all: unset
  cursor: pointer
  display: flex
  gap: var(--spacing-8)
  place-items: center
  > svg > path
    stroke: var(--color-steel-grey-700)

.skeletonWrapper
  height: 32px

.textWrapper
  display: flex
  flex-direction: column
  gap: var(--spacing-8)

.titleWrapper
  display: flex
  flex-direction: column
  gap: var(--spacing-16)
