@use "render/styles/breakpoints"

.StepsSection
  width: 100%
  display: flex
  flex-direction: column
  gap: var(--spacing-24)

.todoList
  margin: 0
  padding: 0
  border-top: 1px solid var(--color-steel-grey-300)
  list-style-type: none

.todoListItem
  border-bottom: 1px solid var(--color-steel-grey-300)
  display: flex

.profileButton
  @media (max-width: breakpoints.$md)
    display: none

.labelMarker
  width: 6px
  height: 6px
  path
    fill: var(--color-optimistic-red-400)

.label
  align-items: center
  display: inline-flex
  gap: var(--spacing-8)
  padding: var(--spacing-4)
  padding-left: var(--spacing-8)
  padding-right: var(--spacing-8)
  background: var(--color-optimistic-red-50)
  border-radius: 4px
  margin-bottom: var(--spacing-8)

.headerSpacer
  height: var(--spacing-8)
