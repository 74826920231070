.ListView
    ul
        margin: 0
        padding: 0
        list-style-type: none

    .empty
        padding: 0 var(--spacing-24)
        margin-top: 80px
        display: grid
        gap: var(--spacing-8)
        .head
            color: #263032
            text-align: center
            font-size: 24px
            font-weight: 300
            line-height: 32px
            letter-spacing: -1px
        .body
            color: #496970
            text-align: center
            font-size: 14px
            font-weight: 400
            line-height: 18px
            letter-spacing: -0.1px
        .link
            background: transparent
            cursor: pointer
            border: none
            padding: 0
            margin: 0
            color: #019DC9
            text-align: center
            font-size: 14px
            font-weight: 400
            line-height: 18px
            letter-spacing: -0.1px
