.FullScreenPageLayout
  color: var(--color-materials-rubber)
  height: 100%
  overflow: hidden auto
  position: relative
  width: 100%

  &[data-background="light-aluminium"]
    background-color: var(--color-materials-light-aluminium)
  &[data-background="porcelain"]
    background-color: var(--color-materials-porcelain)
  &[data-background="transparent"]
    background-color: transparent
