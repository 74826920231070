@use "render/styles/breakpoints"

.MetricGroup
  display: flex
  flex-direction: column
  gap: var(--spacing-16)

  &[data-kind="group"]
    margin-top: var(--spacing-16)

.cards
  display: grid
  gap: var(--spacing-12)
  grid-template-columns: 1fr 1fr

  @media (max-width: breakpoints.$xl)
    grid-template-columns: 1fr

  @media (max-width: breakpoints.$md)
    grid-template-columns: 1fr 1fr

  @media (max-width: breakpoints.$sm)
    grid-template-columns: 1fr
