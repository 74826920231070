.pageHeader
  display: flex
  padding: 20px 20px var(--spacing-16) 20px
  align-items: center
  position: relative

  .closeButton
    position: absolute
    left: 0
    padding: 20px
    cursor: pointer

.text
  display: grid
  gap: 20px
  margin-top: 46px
