.Nav
  position: relative
  display: flex
  flex-direction: row
  justify-content: space-around

.line
  position: absolute
  bottom: 0
  border: 1px solid var(--color-intelligent-blue-600)
  transition: all 0.6s
  left: 0

.NavItem
  flex: 1 1 0px
  display: flex
  justify-content: center
  text-transform: uppercase
  padding: var(--spacing-8) 0 22px 0
  cursor: pointer
  align-items: center
