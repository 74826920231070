.Day
    background: transparent
    cursor: pointer
    border: none
    color: #262C2E
    text-align: center
    font-size: 12px
    font-style: normal
    font-weight: 500
    line-height: 14px
    letter-spacing: 0.6px
    text-transform: uppercase
    margin: 0
    padding: 0
    width: 34px
    height: 34px
    border-radius: 50%
    transition: color 0.2s ease-in, background 0.2s ease-in

    &:hover
        color: #005B84
        background: #E2EFF3

    &:disabled
        cursor: initial
        color: #9EBCC3
        background: transparent

    &[data-today=true]
        color: #005B84
        background: #E2EFF3
        &:disabled
            color: #9EBCC3
            background: #C5D9DD

    &[data-selected=true]
        color: #FBFBFA
        background: #019DC9
        &:disabled
            background: #C5D9DD
