.body
  display: flex
  justify-content: center
  align-items: center
  overflow: auto
  flex: 1

.content
  display: flex
  flex-direction: column
  max-width: 540px
  width: 100%
  margin: auto
  height: initial
  @media (max-width: 600px)
    margin: auto var(--spacing-16) var(--spacing-16) var(--spacing-16)

.description
  display: grid
  gap: var(--spacing-12)

.actions
  align-items: flex-end
  flex: 1
  display: flex
  margin: 32px 0 0 0
  @media (max-width: 600px)
    margin: 0 0 var(--spacing-16) 0
    margin-bottom: var(--spacing-16)

.PaymentRequiredView
  height: 100%
  display: flex
  flex-direction: column
