.Button
  width: 100%
  height: 100%
  box-sizing: border-box
  display: flex
  flex-direction: row
  align-items: center
  padding: var(--spacing-24)
  border-radius: var(--spacing-16)
  border: 1px solid transparent
  transition: border-radius 0.6s, color 0.6s, background-color 0.6s
  cursor: pointer
  text-wrap: nowrap
  font-family: "IBM Plex Sans Medium"
  font-size: 16px
  font-style: normal
  font-weight: 500
  line-height: 24px
  letter-spacing: -0.1px

  @media (hover: hover)

    &:hover
      border-radius: 0px

  &:disabled
    cursor: initial

    &:hover
      border-radius: var(--spacing-16)
      color: initial

.paddingBig
  padding: var(--spacing-24)

.paddingSmall
  padding: 20px

  @media (max-width: 600px)
    padding: var(--spacing-16)

.variantActive
  color: var(--color-materials-porcelain)
  background-color: var(--color-intelligent-blue-600)

.variantContained
  font-size: 20px
  font-style: normal
  font-weight: 400
  line-height: 24px
  letter-spacing: -0.4px
  color: var(--color-intelligent-blue-600)
  background-color: var(--color-materials-porcelain)

  &:hover
    color: var(--color-intelligent-blue-600)

  &:disabled
    color: var(--color-steel-grey-900)
    background-color: transparent
    border: 1px solid
    border-color: var(--color-steel-grey-400)

.variantUnfocused
  color: var(--color-steel-grey-900)
  background-color: transparent
  border: 1px solid
  border-color: var(--color-steel-grey-400)

.variantOutlined
  color: var(--color-steel-grey-900)
  background-color: transparent
  border: 1px solid
  border-color: var(--color-steel-grey-400)

.alignCenter
  justify-content: center

.alignSpread
  justify-content: space-between
