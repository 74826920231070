@use "render/styles/breakpoints"

.modal
  width: 100%
  height: 100%
  display: flex
  opacity: 1
  flex-direction: row
  align-items: flex-start
  justify-content: flex-end
  overflow: hidden

  .modalContent
    width: 50%
    height: 100%
    &.active
      transition: opacity 0.2s, transform 0.35s
      opacity: 1,
      transform: translateX(0%)
    &.hidden
      transition: opacity 0.15s linear 0.2s, transform 0.35s
      opacity: 0,
      transform: translateX(100%)

    @media (max-width: breakpoints.$md)
      width: 100%
