@use "render/styles/breakpoints"

.HeaderLayout
  display: flex
  flex-direction: column
  gap: var(--spacing-4)
  b
    font-weight: 500
  .title
    font-family: Orleans Roman, sans-serif
    color: #263032
    font-size: 64px
    letter-spacing: -1px
    line-height: 1.1
    @media (max-width: breakpoints.$md)
      font-size: 42px
      letter-spacing: -1px
      line-height: 1.1
  .subTitleContainer
    display: grid
    gap: var(--spacing-24)
  .subTitle
    font-weight: 400
    color: #4c6a70
    font-size: 14px
    letter-spacing: 0em
    line-height: 1.2
