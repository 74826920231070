@use "render/styles/breakpoints"

.Avatar
  position: sticky
  top: 173px
  border-radius: var(--spacing-16)
  transition: height 0.3s ease-out
  display: flex
  justify-content: center
  overflow: hidden
  @media (max-width: breakpoints.$md)
    top: 108px
    border-radius: 0

.text
  padding-top: var(--spacing-8)
  display: flex
  justify-content: space-between

  & *
    color: var(--color-intelligent-blue-600)
