.variantError
  background: var(--color-optimistic-red-400) !important

.variantBIGSuccess
  background: var(--color-intelligent-blue-600) !important

.root
  font-family: 'IBM Plex Sans Regular'
  color: var(--color-materials-porcelain)

  font-size: 14px
  letter-spacing: 0px
  line-height: 1.2
  text-align: left

.containerRoot
  width: 100% !important
