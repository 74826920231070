$_duration: 0.3s

.backdrop
  display: flex
  pointer-events: none
  transition: $_duration ease
  transition-property: backdrop-filter, background-color

  [data-active=true] &
    pointer-events: unset
    background-color: hsla(from var(--color-materials-rubber) h s l / 0.5)
