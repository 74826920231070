@use "easing" as easing

@keyframes fade-in
  0%
    pointer-events: none
    opacity: 0
    transform: translateY(176px)
  99%
    pointer-events: initial
  100%
    transform: translateY(0px)
    opacity: 1

.body
  display: flex
  justify-content: center
  align-items: center
  overflow: auto
  min-height: 100%

.content
  animation-name: fade-in
  animation-duration: 0.5s
  animation-delay: 1.5s
  animation-fill-mode: both
  animation-timing-function: easing.$neko-0-75

  overflow: initial
  max-width: 540px
  width: 100%
  margin-top: 0
  margin-top: auto
  margin-bottom: auto
  display: grid
  gap: var(--spacing-12)
  @media (max-width: 600px)
    overflow: hidden
    margin: auto var(--spacing-16) var(--spacing-16) var(--spacing-16)
