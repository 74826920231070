@use "render/styles/breakpoints"

.Backdrop
  height: 100%
  pointer-events: none
  transition: 0.5s cubic-bezier(0.45, 0.00, 0.25, 1.00)
  transition-property: backdrop-filter, background-color
  width: 100%

  .content
    height: 100%
    position: absolute
    right: 0
    transform: translateY(100%)
    transition: inherit
    transition-property: transform
    overflow: hidden
    width: 100%

    @media (min-width: breakpoints.$sm)
      transform: translateX(100%)
      max-width: 620px
      width: 60%

  &[data-size="half"]
    .content
      max-width: unset

      @media (min-width: breakpoints.$sm)
        width: 50%

  &[data-active="true"]
    pointer-events: all

    .content
      transform: unset

    &[data-kind="blur"]
      backdrop-filter: blur(20px)
      background-color: rgba(255, 255, 255, 0.3)

    &[data-kind="subtle"]
      backdrop-filter: blur(6px)
      background-color: rgba(0, 0, 0, 0.5)

    &[data-kind="transparent"]
      backdrop-filter: unset
      background-color: unset
