.OpaqueLayer
  inset: 0
  opacity: 0
  overflow: hidden
  pointer-events: none
  position: absolute
  transition: opacity 0.35s
  z-index: 999

  &[data-active="true"]
    opacity: 1
    pointer-events: all

  &[data-variant="blur"]
    background-color: rgba(255, 255, 255, 0.3)
    backdrop-filter: blur(20px)

  &[data-variant="dark"]
    background-color: color(from var(--color-materials-rubber) srgb r g b / 50%)
