.IconButton
  display: block
  padding: 0
  cursor: pointer
  border: none
  background: transparent
  transition: color 0.4s
  color: var(--color-materials-rubber)

  &:focus-visible
    outline: -webkit-focus-ring-color solid 1px

  &[data-disabled=true]
    cursor: initial

  svg
    display: block
