.body
  height: 100%
  width: 100%
  overflow: auto

.content
  max-width: 540px
  padding-top: 44px
  margin: 0px auto

.headings
  display: grid
  gap: var(--spacing-8)
  padding: 0 var(--spacing-16)

.tokens
  margin-top: 44px
  background-color: var(--color-materials-porcelain)
  border-radius: var(--spacing-16)
  padding: var(--spacing-4) var(--spacing-24)
  list-style-type: none
  margin-bottom: 32px

  .token
    padding: 22px 0

    &:not(:last-of-type)
      border-bottom: 1px solid var(--color-steel-grey-400)
