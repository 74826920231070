.button
  outline: none
  border: none
  border-radius: var(--spacing-4)
  background: transparent
  color: var(--color-materials-rubber)

  :not(:disabled)
    cursor: pointer

  &:focus-visible
    outline: -webkit-focus-ring-color solid 1px

.card
  position: relative
  width: var(--spacing-52)
  height: var(--spacing-52)

  .bg
    position: absolute
    top: 6px
    left: 14px
    width: 32px
    height: 42px
    transform: rotate(8deg)
    transform-origin: bottom center
    border-radius: var(--spacing-4)
    border: 1px solid rgba(0, 0, 0, 0.10)
    object-fit: cover
    transition: transform 0.4s

  .iconContainer
    $icon-width: 28px
    $icon-height: 28px
    position: absolute
    left: 0
    top: calc(50% - calc($icon-height / 2))
    width: $icon-width
    height: $icon-height
    border-radius: var(--spacing-8)
    border: 1px solid rgba(0, 0, 0, 0.10)
    background: var(--color-materials-porcelain)
    display: flex
    justify-content: center
    align-items: center
    transform-origin: bottom center
    transition: transform 0.4s

  .icon
    transition: transform 0.2s

  &:active
    .icon
      transform: scale(0.9)

  @media(hover: hover)
    &:hover
      .bg
        transform: translateY(-4px) rotate(12deg)

      .iconContainer
        transform: translateY(-4px) rotate(-4deg)

    &:active:hover
      .iconContainer
        transform: translateY(-4px) scale(0.9)
