@keyframes fill
  0%
    background-position: right bottom
  100%
    background-position: left bottom

.BookingActions
  display: flex
  justify-content: space-between

  .book
    box-sizing: border-box
    margin: 0
    background: #019DC9
    padding: var(--spacing-16) var(--spacing-24)
    border: none
    cursor: pointer
    border-radius: var(--spacing-12)
    border: 1px solid #019DC9

    &:disabled
      border-color: #E3E5E5
      background: #E3E5E5
      cursor: initial

    &[data-inprogress=true]
      background: linear-gradient(to right, #E3E5E5 50%, #F0F0F0 50%) left
      background-size: 200% 100%
      animation: fill
      animation-duration: 3s
      animation-fill-mode: both
      border-color: #E3E5E5

.date
  display: inline-block // ::first-letter requires block element
  &::first-letter
    text-transform: uppercase
