.cta
    padding: var(--spacing-24)
    display: flex
    justify-content: center

.action
    width: 100%
    max-width: 540px
    gap: var(--spacing-8)
    display: flex
    flex-direction: column
    @media (max-width: 600px)
        max-width: 100%

.body
    display: flex
    justify-content: center
    align-items: flex-start
    overflow: auto
    overflow-x: hidden

.content
    display: flex
    flex-direction: column
    gap: var(--spacing-12)
    max-width: 540px
    width: 100%
    margin-top: auto
    margin-bottom: auto
    @media (max-width: 600px)
        margin: 32px var(--spacing-24) 0 var(--spacing-24)

.textContainer
    display: grid
    gap: var(--spacing-24)

.textWithIcon
    display: flex
    flex-direction: row
    align-items: center
    gap: var(--spacing-8)
