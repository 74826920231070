.LanguageSelector
  display: flex
  flex-direction: column

  &[data-separator=true]
    border-bottom: solid 1px var(--color-steel-grey-300)
    padding-bottom: 22px
    padding-top: var(--spacing-24)

  .label
    text-transform: uppercase
