$_spacing: var(--spacing-24)

.container
  display: flex
  flex-direction: column

.body
  box-sizing: content-box
  display: grid
  flex: 1
  gap: 32px
  margin: 0 auto
  max-width: 540px
  padding: 32px $_spacing
  place-content: space-between

.content
  display: grid
  gap: var(--spacing-24)

.image
  width: 100%
  @media (max-width: 580px)
    margin: 0 (-$_spacing)
    width: calc(100% + (2*$_spacing))

.sticky
  backdrop-filter: blur(20px)
  position: sticky
  top: 0
  z-index: 1
