@keyframes shine
  to
    background-position-x: -200%

.Skeleton
  background: #eee
  background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%)
  border-radius: var(--spacing-4)
  background-size: 200% 100%
  animation: 1.5s shine linear infinite
  height: 100%
  width: 100%
