.body
  height: 100%
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  text-align: center
  overflow-y: auto
  overflow-x: hidden

.content
  max-width: 540px
  margin: 0 auto
  display: flex
  flex-direction: column
  gap: var(--spacing-24)
