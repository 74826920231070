@use "render/styles/breakpoints"

.AppointmentPrereqView
  width: 100%
  min-height: 100%
  display: flex
  flex-direction: column

.content
  margin: var(--spacing-72) auto 0
  max-width: 440px
  width: 100%
  flex-direction: column
  display: flex
  flex: 1
  gap: var(--spacing-36)
  z-index: 0

  @media (max-width: breakpoints.$sm)
    max-width: 100%
    margin-top: var(--spacing-36)
    padding: 0 var(--spacing-24)

.text
  display: grid
  gap: var(--spacing-16)

  br
    @media (min-width: breakpoints.$sm)
      display: none

.button
  margin: var(--spacing-16) 0 var(--spacing-24)
  display: flex
  @media (max-width: breakpoints.$sm)
    margin-top: auto

  > *
    flex: 1
    justify-content: space-between

.background
  position: fixed
  transform: translateX(-50%)
  bottom: 0
  left: 50%
  width: 1500px
  pointer-events: none

  @media (max-width: breakpoints.$sm)
    display: none
