.studyState
  color: var(--color-steel-grey-700)
  display: flex
  gap: var(--spacing-8)
  padding: var(--spacing-12) 0
  place-items: center
  &[data-warn="true"]
    color: var(--color-optimistic-red-400)
  svg
    path
      stroke: currentColor
