@use '_easing' as easing

.Accordion
  border: none
  background: transparent
  padding: 0
  width: 100%
  text-align: start
  display: flex
  flex-direction: column
  overflow: hidden
  position: relative

.header
  cursor: pointer
  display: flex
  width: 100%
  align-items: center
  justify-content: space-between
  padding: var(--spacing-12) 0

.content
  padding-bottom: var(--spacing-12)

.icon
  transform: rotate(0deg)
  transition: transform 0.3s easing.$neko-45-75
  &[data-open="true"]
    transform: rotate(45deg)
