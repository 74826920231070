@use "easing" as easing

@keyframes fade-in
  0%
    pointer-events: none
    opacity: 0
    transform: translateY(var(--offset))
  99%
    pointer-events: initial
  100%
    transform: translateY(0px)
    opacity: 1

.FadeIn
  animation-name: fade-in
  animation-duration: 0.5s
  animation-fill-mode: both
  animation-timing-function: easing.$neko-0-75
