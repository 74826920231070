.profile
  padding-top: 44px
  padding-bottom: var(--spacing-52)

.nameSection
  border-color: var(--color-steel-grey-300)
  border-bottom-style: solid
  border-bottom-width: 1px
  padding: 44px 0 20px 0

.name
  font-weight: 300
  color: var(--color-steel-grey-900)
  font-size: 38px
  letter-spacing: -1px
  line-height: 1.1

.id
  font-weight: 400
  color: var(--color-steel-grey-700)
  font-size: 14px
  letter-spacing: -0.2px
  line-height: 1

.consentSection
  display: flex
  align-items: flex-end

.consentItem
  flex: 1

.content
  padding: 0 var(--spacing-16)
  max-width: 560px
  width: 100%
  margin: 0 auto

.separator
  padding: 32px 0 var(--spacing-24) 0

.loading
  height: 100%
  display: grid
  gap: var(--spacing-24)

  .skeletonHead
    height: 46px
    width: 60%

  .skeletonFields
    display: grid
    gap: var(--spacing-24)

  .skeletonField
    display: grid
    padding-bottom: var(--spacing-24)
    gap: var(--spacing-8)
    border-bottom: 1px solid #c5d9dd

    &:first-child
      padding-top: var(--spacing-24)
    &:last-child
      border-bottom: none

    .skeletonLabel
      height: var(--spacing-16)
      width: 50px

    .skeletonValue
      height: 32px
      display: grid
      grid-template-columns: auto 32px
      gap: var(--spacing-12)

  .skeletonButton
    height: 80px

.section
  display: flex
  flex-direction: column
  gap: var(--spacing-36)
