$_duration: 0.3s

.dialog
  background: none
  border: 0
  display: block
  inset: 0
  max-width: calc(440px + var(--spacing-24) * 2)
  opacity: 0
  outline: none
  padding: var(--spacing-24)
  transition: $_duration ease
  transition-property: opacity

  [data-active=true] &
    opacity: 1
