@use "render/styles/breakpoints"

.StepView
  display: flex
  flex-direction: column
  min-height: 100%
  padding: 0 var(--spacing-16)
  overflow: auto

.progress
  display: flex
  flex-direction: row
  flex-wrap: nowrap
  color: var(--color-intelligent-blue-600)
  align-items: center
  height: 1px
  gap: var(--spacing-4)

.progressLine
  height: 100%
  background: var(--color-materials-rubber)
  border-radius: 2px

.remainingLine
  height: 100%
  background: var(--color-materials-aluminium)
  width: 100%
  border-radius: 2px

.screenReaderProgressBar
  border: 0
  padding: 0
  margin: 0
  position: absolute !important
  height: 1px
  width: 1px
  overflow: hidden
  /* IE6, IE7 - a 0 height clip, off to the bottom right of the visible 1px box */
  clip: rect(1px 1px 1px 1px)
  /*maybe deprecated but we need to support legacy browsers */
  clip: rect(1px, 1px, 1px, 1px)
  /*modern browsers, clip-path works inwards from each corner*/
  clip-path: inset(50%)
  /* added line to stop words getting smushed together (as they go onto seperate lines and some screen readers do not understand line feeds as a space */
  white-space: nowrap

.label
  font-size: 12px

.caption
  font-size: 32px
  color: var(--color-steel-grey-900)
  font-weight: 500

.sticky
  background: var(--color-materials-light-aluminium)
  top: 0
  position: relative
  z-index: 0
  display: flex
  flex-direction: column
  gap: var(--spacing-96)

  @media (max-width: breakpoints.$sm)
    gap: var(--spacing-24)

  @media (min-height: 720px)
    position: sticky
    z-index: 1

.progressLineBottom
  width: 100%
  height: 5px

.nav
  padding: var(--spacing-16)
  margin: 0 auto
  max-width: 440px
  @media (max-width: breakpoints.$md)
    max-width: 100%

.why
  cursor: pointer

.subCaption
  margin-top: var(--spacing-8)
  font-weight: 400
  color: var(--color-steel-grey-900)
  font-size: 14px
  letter-spacing: -0.1px
  line-height: 1.2

.stacked
  display: grid
  grid-area: 1 / 1 / 2 / 2

.body
  max-width: 440px
  margin: 0 auto
  width: 100%
  display: flex
  flex-direction: column
  gap: var(--spacing-24)

  @media (max-width: breakpoints.$md)
    max-width: 100%

.question
  display: flex
  flex-direction: column
  gap: var(--spacing-8)

.captions
  display: flex
  flex-direction: column
  gap: var(--spacing-8)

.explanation
  display: flex
  flex-direction: column
  gap: var(--spacing-12)

.content
  display: flex
  flex-direction: column
  padding-block-end: var(--spacing-12)
