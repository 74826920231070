@keyframes scaleIn
  0%
    opacity: 0
    background: transparent
    height: var(--spacing-8)
    width: var(--spacing-8)
  100%
    height: 32px
    width: 32px
    opacity: 1

@keyframes border
  0%
    border: 1px solid #9EBCC3
  99%
    border: 1px solid #9EBCC3
  100%
    border: 1px solid #019dc9

.ProgressDayCheckBox
  gap: var(--spacing-4)
  flex-direction: column
  display: flex
  justify-content: center
  align-items: center
  font-weight: 500
  color: #4c6a70
  font-size: 10px
  text-transform: uppercase
  line-height: 1
  text-align: left
  &.hidden
    display: none
  button
    box-sizing: border-box
    width: 32px
    height: 32px
    border-radius: var(--spacing-12)
    padding: 0
    margin: 0
    cursor: pointer
    overflow: hidden
    background: transparent
    border: 1px solid #9EBCC3
    display: flex
    justify-content: center
    align-items: center

    &:disabled
      cursor: initial
    &.completed
      transition: border 0.2s
      animation: border
      animation-timing-function: cubic-bezier(.17,.67,.83,.67)
      animation-fill-mode: both
      animation-iteration-count: 1
    .icon
      background-color: #019dc9
      border-radius: var(--spacing-12)
      overflow: hidden
      display: flex
      justify-content: center
      align-items: center
      animation: scaleIn
      animation-timing-function: cubic-bezier(.17,.67,.83,.67)
      animation-fill-mode: both
      animation-iteration-count: 1

    &.empty
      background: transparent

    svg
      display: block
