.Carousel
  position: relative
  height: 100%

.item
  height: 100%
  overflow: auto
  position: absolute
  top: 0
  transition: opacity 0.2s, transform 0.6s
  width: 100%

  &[data-next=true], &[data-prev=true]
    opacity: 0
    pointer-events: none
    transition: opacity 0.6s, transform 0.6s

  &[data-next=true]
    transform: translateX(100%)

  &[data-prev=true]
    transform: translateX(-100%)
