@use "render/styles/breakpoints"

.page
  @media (min-width: breakpoints.$md)
    background: var(--color-materials-porcelain)

.header
  backdrop-filter: blur(20px)
  position: sticky
  top: 0
  @media (min-width: breakpoints.$md)
    backdrop-filter: unset
    background: inherit
    padding: var(--spacing-4) var(--spacing-16)

.body
  box-sizing: content-box
  margin: 0 auto
  max-width: 440px
  padding: var(--spacing-24)
  @media (min-width: breakpoints.$md)
    max-width: unset
    padding: var(--spacing-24) 40px
