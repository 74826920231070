.inputContainer
  position: relative
  width: 100%
  padding-top: var(--spacing-24)

.input
  padding: 0 var(--spacing-36) 22px 0
  width: 100%
  background-color: transparent
  transition: 0.2s ease all
  border-top: none
  border-left: none
  border-right: none
  border-bottom: solid 1px var(--color-intelligent-blue-600)
  border-radius: 0px
  caret-color: var(--color-intelligent-blue-900)
  color: var(--color-materials-rubber)
  font-family: "IBM Plex Sans Regular"
  font-size: 16px
  font-style: normal
  font-weight: 400
  line-height: 24px
  letter-spacing: -0.1px

  &:focus ~.labelTop, &:not(:placeholder-shown) ~ .labelTop
    transition: 0.2s ease all
    opacity: 1
    transform: translate(0px, -30px)

  &:focus ~ .label, &:not(:placeholder-shown) ~ .label
    opacity: 0
    transition: none
    font-size: 12px
    transform: translate(0px, -16px)

  &:not(:placeholder-shown)
    border-bottom-color: var(--color-steel-grey-700)
    & ~ .label, & ~ .labelTop
      color: var(--color-steel-grey-700)

  &:focus
    border-bottom: solid 1px var(--color-intelligent-blue-600)
    & ~ .label, & ~ .labelTop
      color: var(--color-intelligent-blue-900)

.label
  opacity: 1
  font-weight: 400
  font-style: normal
  font-family: "IBM Plex Sans Regular", sans-serif
  font-size: 16px
  letter-spacing: -0.1px
  line-height: 24px
  left: 0
  bottom: 22px
  transform: translate(0, 0)
  position: absolute
  pointer-events: none
  transition: 0.2s ease all, 0.4s ease ont-weight
  color: var(--color-intelligent-blue-900)

.error
  bottom: 5px
  position: absolute
  color: var(--color-optimistic-red-400)
  font-weight: 400
  font-size: 12px
  letter-spacing: 0px
  line-height: 1.1

  & ~ .label, & ~ .labelTop
    color: var(--color-optimistic-red-400)
  & ~ .input
    border-bottom-color: var(--color-optimistic-red-400)
    & ~ .label, & ~ .labelTop
      color: var(--color-optimistic-red-400)

.labelTop
  opacity: 0
  font-style: normal
  font-family: "IBM Plex Sans Medium", sans-serif
  left: 0
  bottom: 22px
  position: absolute
  color: var(--color-intelligent-blue-900)
  text-transform: uppercase
  font-weight: 500
  font-size: 12px
  letter-spacing: 0.6px
  line-height: 16px
  transform: translate(0px, -20px)

.cta
  cursor: pointer
  position: absolute
  right: 0
  background-color: var(--color-materials-porcelain)
  border: none
  padding: var(--spacing-8) var(--spacing-12)
  border-radius: var(--spacing-12)
  font-weight: 500
  font-size: 14px
  line-height: 160%
  letter-spacing: -0.03em
  color: #262C2E
  bottom: 20px
  @media (hover: hover)
    &:hover
      color: var(--color-intelligent-blue-900)

  & ~ .clearButton
    right: 66px
  & ~ .input
    padding-right: 102px

.clearButton
  transition: 0.2s ease all
  background: transparent
  border: none
  cursor: pointer
  position: absolute
  right: 0
  bottom: 20px
