.RadioButtons
  display: flex
  flex-direction: column

  .label
    color: #4c6a70
    font-size: 12px
    font-weight: 500
    letter-spacing: 0px
    line-height: 1
    text-transform: uppercase

  .button
    align-items: center
    background: none
    border: none
    cursor: pointer
    display: flex
    flex-direction: row
    gap: var(--spacing-16)
    margin: var(--spacing-12) 0
    padding: 0

    &:focus-visible
      outline: -webkit-focus-ring-color solid 1px

    & input[type=radio]
      opacity: 0
      position: absolute

    .radio
      align-items: center
      display: flex
      height: 32px
      justify-content: center
      width: 32px
      .frame
        align-items: center
        border: 1px solid #9ebcc3
        border-radius: var(--spacing-12)
        display: flex
        height: 28px
        justify-content: center
        width: 28px
        .dot
          background-color: #019dc9
          border-radius: 3px
          height: 1px
          opacity: 0
          width: 1px
    &:hover
      .option
        color: #019dc9
      .radio
        .frame
          border-color: #019dc9
          .dot
            height: var(--spacing-4)
            opacity: 1
            width: var(--spacing-4)
    &[value="on"]
      .option
        color: #019dc9
      .radio
        .frame
          border-color: #019dc9
          .dot
            border-radius: 0
            height: 6px
            opacity: 1
            width: 6px
