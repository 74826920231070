.card
  border: 1px solid var(--color-materials-aluminium)
  border-radius: var(--spacing-16)
  display: grid
  gap: var(--spacing-16)
  padding: var(--spacing-24)
  place-items: center

  &[data-variant="done"]
    background-color: var(--color-intelligent-blue-50)
    border-color: var(--color-intelligent-blue-100)

  > h3
    text-align: center

.buttons
  display: flex
  gap: var(--spacing-8)

  > button
    min-width: 132px

.form
  display: grid
  gap: var(--spacing-24)

  > h3
    text-align: center

  > [data-self-center]
    place-self: center

.tags
  display: flex
  flex-wrap: wrap
  gap: var(--spacing-8)
  padding: var(--spacing-12) 0

.tag
  border: 1px solid var(--color-steel-grey-300)
  border-radius: var(--spacing-4)
  cursor: pointer
  padding: var(--spacing-8) var(--spacing-12)
  transition: 0.2s cubic-bezier(0.17, 0.17, 0.25, 1)
  transition-property: background-color, border-color, color

  &:has(:focus-visible)
    outline: 1px solid var(--color-materials-rubber)

  @media (pointer: fine)
    &:hover
      border-color: var(--color-steel-grey-600)
      &:has(:checked)
        border-color: var(--color-intelligent-blue-900)

  &:active, &:has(:checked)
    background-color: var(--color-intelligent-blue-50)
    border-color: var(--color-intelligent-blue-600)
    color: var(--color-intelligent-blue-900)

  > input
    position: absolute
    height: 0
    width: 0

// TODO: replace with maneki component
.textarea
  color: var(--color-intelligent-blue-900)
  display: grid
  gap: var(--spacing-4)

  > textarea
    background: unset
    border: 0
    border-bottom: 2px solid var(--color-steel-grey-300)
    border-radius: 0
    color: var(--color-materials-rubber)
    font-family: var(--typography-bodyM-fontFamily)
    font-size: var(--typography-bodyM-fontSize)
    font-style: var(--typography-bodyM-fontStyle)
    font-weight: var(--typography-bodyM-fontWeight)
    letter-spacing: var(--typography-bodyM-letterSpacing)
    line-height: var(--typography-bodyM-lineHeight)
    outline: none
    padding: 0
    resize: vertical

    &:focus
      border-color: var(--color-intelligent-blue-600)
