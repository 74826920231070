@use "render/styles/breakpoints"

.ProgressTable
  display: grid
  gap: var(--spacing-24)
  margin: var(--spacing-24) 0
  @media (max-width: breakpoints.$md)
    margin: var(--spacing-24) calc(-1 * var(--spacing-24))

  .item
    height: 203px
    grid-column: 1
    grid-row: 1

  .values
    display: grid
    grid-template-columns: minmax(30px, auto) repeat(7, 1fr) minmax(30px, auto)
    .data
      grid-column: span 7
      display: grid
      grid-template-columns: repeat(7, 1fr)

  .breakpointColumn
    position: relative
    .breakpointText
      transform: translateY(-50%)
      font-size: 12px
      letter-spacing: -0.3px
      line-height: 18px
      position: absolute
      color: rgb(76, 106, 112)
      background: var(--color-materials-light-aluminium)
      width: 100%
      text-align: center
