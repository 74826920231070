.studiesSection
  display: flex
  flex-direction: column
  gap: 48px
  max-width: 540px
  margin: 0px auto 22px auto
  height: 100%
  overflow-x: hidden
  overflow-y: auto
  padding: 44px var(--spacing-16) 22px var(--spacing-16)

.title
  display: flex
  flex-direction: column
  gap: var(--spacing-24)

.consentBorder
  border-bottom: 1px var(--color-steel-grey-400) solid

.sectionTitle
  padding-block-end: var(--spacing-16)

.section
  display: flex
  flex-direction: column
  gap: var(--spacing-24)

.cardsWrapper
  display: flex
  flex-direction: column
  gap: var(--spacing-16)

.iconSkeleton
  height: var(--spacing-16)
  width: var(--spacing-16)

.gdpr
  border: 0 solid var(--color-steel-grey-300)
  border-width: 1px 0
  display: grid
  grid-template-columns: 1fr auto
  gap: var(--spacing-36)
  padding: var(--spacing-24) 0
  place-items: center

.text
  display: grid
  gap: var(--spacing-8)
