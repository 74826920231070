.badge
  font-size: 10px
  line-height: 1
  letter-spacing: 1px
  font-weight: 500
  width: fit-content
  text-transform: uppercase
  display: flex
  justify-content: center
  align-items: center
  border-radius: var(--spacing-4)
  height: fit-content

  &[data-padding=true]
    padding: var(--spacing-4) var(--spacing-8)
