@use "easing" as easing
@use "render/styles/breakpoints"

.CodePaymentView
  display: flex
  flex-direction: column
  min-height: 100%
  gap: var(--spacing-36)
.content
  flex: 1
  display: flex
.body
  display: flex
  flex-direction: column
  gap: var(--spacing-24)
  margin: auto
  max-width: 540px
  width: 100%
  @media (max-width: breakpoints.$md)
    margin: 0 var(--spacing-24) var(--spacing-24)
    max-width: 100%

.description
  display: grid
  gap: var(--spacing-12)

.actions
  display: grid
  padding-top: 34px
  @media (max-width: breakpoints.$md)
    margin-top: auto

.buttons
  display: grid
  gap: var(--spacing-8)
  transform: translateY(0)
  opacity: 1
  transition: all 0.5s easing.$neko-0-75
  &[data-loading=true]
    opacity: 0
    transform: translateY(20px)

.header
  position: sticky
  top: 0
  backdrop-filter: blur(20px)

.country
  gap: var(--spacing-8)
  display: flex

.addCode
  display: flex
  justify-content: space-between

.addCodeButton
  background: transparent
  display: flex
  gap: var(--spacing-8)
  align-items: center
  padding: 0
  margin: 0
  cursor: pointer
  border: none
  &:disabled
    cursor: unset
    svg
      color: var(--color-steel-grey-700)

  svg
    color: var(--color-materials-rubber)

.locationPlaceholder
  height: 18px
  width: 100%

.callToActionSkeleton
  height: 80px
  width: 100%
  overflow: hidden
  border-radius: var(--spacing-16)

.product
  border-bottom: 1px solid var(--color-steel-grey-300)
  padding: var(--spacing-16) 0 var(--spacing-24)

.icon
  height: 40px
  display: flex
  justify-content: center
  align-items: center
