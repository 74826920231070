// A
$neko-0-75: cubic-bezier(0.17, 0.17, 0.25, 1)

// B
$neko-45-75: cubic-bezier(0.45, 0, 0.25, 1)

// C
$neko-75-55: cubic-bezier(0.75, 0, 0.55, 1)

// D
$neko-75-25: cubic-bezier(0.75, 0, 0.25, 1)

// E
$neko-31-40: cubic-bezier(0.31, 0, 0.4, 1)

// F
$neko-50-30: cubic-bezier(0.50, 0, 0.3, 1)

// G
$neko-30-30: cubic-bezier(0.3, 0, 0.3, 1)
