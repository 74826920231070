.ConciergeCheckInView
  height: 100%
  display: grid
  transition: background 0.5s
  &[data-status="complete"]
    background: var(--color-steel-grey-300)
  &[data-status="loading"]
    background: var(--color-materials-light-aluminium)
  &[data-status="error"]
    background: var(--color-materials-light-aluminium)

  section
    grid-column: 1
    grid-row: 1
    overflow: auto
    transition: opacity 0.5s
    &[data-active=true]
      pointer-events: initial
      opacity: 1
      visibility: visible
    &[data-active=false]
      pointer-events: none
      opacity: 0
      visibility: hidden

  .body
    display: flex
    overflow: auto
    min-height: 100%

    .content
      margin: auto
      max-width: 420px

.loader
  display: grid
  > *
    grid-row: 1
    grid-column: 1
