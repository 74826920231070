@use 'easing' as easing

.animatedLine
    animation-name: animateIn, animateOut
    animation-timing-function: easing.$neko-31-40, easing.$neko-50-30
    animation-fill-mode: forwards, forwards
    animation-duration: var(--duration)
    animation-delay: var(--delay)
    animation-play-state: var(--play-state)

    opacity: 0
    transform: translateY(var(--in-translate-y))

    &:not(:last-child)
        padding-bottom: 2px

@keyframes animateIn
    15%
        opacity: 0
    100%
        opacity: 1
        transform: translateY(0)

@keyframes animateOut
    15%
        opacity: 1
    100%
        opacity: 0
        transform: translateY(var(--out-translate-y))
