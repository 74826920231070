.text
  width: 100%
  text-align: left
  display: grid
  gap: 6px
  padding-right: 44px

.button
  display: flex
  flex-direction: column
  text-align: left
  width: 100%
  .icon
    align-self: flex-end

.BookStage
  display: grid
  gap: 22px

.waitlistStage
  display: grid
  gap: 22px
