.LinkTo
  color: var(--color-materials-rubber)
  display: flex
  flex-direction: column
  cursor: pointer
  padding: var(--spacing-24) var(--spacing-24) var(--spacing-24) var(--spacing-24)
  background: var(--color-materials-porcelain)
  width: 100%
  border-radius: var(--spacing-24)
  transition: all 0.6s
  border: none
  gap: var(--spacing-24)
  justify-content: space-between
  min-height: 100%
  *
    transition: color 0.6s !important
  .text
    display: grid

  &.variantSquare
    flex-direction: column
    .icon
      align-self: flex-end

  &.variantWide
    flex-direction: row
    .icon
      align-self: center

  @media(hover: hover) and (pointer: fine)
    &:hover
      border-radius: 0
      *
        color: var(--color-intelligent-blue-600) !important
