.row
  display: flex
  gap: var(--spacing-4)

.icon
  border-radius: var(--spacing-4)
  background: var(--color-materials-aluminium)
  &[data-change='decrease']
    transform: rotate(180deg)
  &[data-change='noChange']
    transform: rotate(90deg)
